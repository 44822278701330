import { isValidDate } from "gx-npm-lib";
import {
  getMenuItemByState,
  getStateLabel,
  initiativeStateLabels,
  isArchiveOrDeleteState,
} from "./get-initiatives-state";

const formatDate = (date: string) => {
  const dateWithTime = date + "T00:00:00";
  return (
    isValidDate(dateWithTime) &&
    new Date(dateWithTime)
      .toLocaleDateString("en-us", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      .split(" ")
      .join(" ")
  );
};

const featureFlags = {};

export { formatDate, getMenuItemByState, getStateLabel, isArchiveOrDeleteState, initiativeStateLabels, featureFlags };
