import * as singleSpa from "single-spa";

const profileRedirectCallback = (data = { isProfileIncomplete: false }) => {
  if (!data.isProfileIncomplete) {
    return;
  }
  singleSpa.navigateToUrl("/s/evaluations#/profile");
};

export default profileRedirectCallback;
