import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { InitState } from "gx-npm-lib";
import { TypographyComponent } from "gx-npm-ui";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { DashBoardAppContext } from "../../app.context";
import { getStateLabel, initiativeStateLabels, isArchiveOrDeleteState } from "../../lib";
import ToggleLink from "../../ui/toggle-link/index";
import { HeaderStyle as styles } from "./dashboard-header.styles";

const useStyles = makeStyles(() => styles);
const DashboardHeader = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { currentState, setCurrentState } = useContext(DashBoardAppContext);
  const displayStateLabel = isArchiveOrDeleteState(currentState);
  const stateLabel = getStateLabel(currentState);

  const handleStateChange = (selectedState: InitState) => {
    setCurrentState(selectedState);
  };

  return (
    <div className={classNames(classes.container)}>
      <TypographyComponent color="carbon" styling="h3">
        {t("My evaluations")}
      </TypographyComponent>
      <TypographyComponent color="iron" rootClassName={classes.stateLabel} styling="p1">
        {displayStateLabel && `( ${t(stateLabel)} )`}
      </TypographyComponent>
      {currentState && (
        <ToggleLink defaultActiveItem={InitState.ACTIVE} items={initiativeStateLabels} onChange={handleStateChange} />
      )}
    </div>
  );
};

export default DashboardHeader;
