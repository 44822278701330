import { makeStyles, Tab, Tabs } from "@material-ui/core";
import { FreeTrialRole, InitState, useUserState } from "gx-npm-lib";
import { TooltipV2, useFeatureFlag } from "gx-npm-ui";
import PropTypes from "prop-types";
import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { DashBoardAppContext } from "../../app.context";
import styles from "./styles";
import { GCOM_3695_ctaButtonColorUpdate } from "../../lib/feature-flags";
import classNames from "classnames";

const propTypes = {
  items: PropTypes.array,
  rootClassName: PropTypes.string,
  onChange: PropTypes.func,
  defaultActiveItem: PropTypes.oneOf([...Object.values(InitState), ""]),
};
const useStyles = makeStyles(() => styles);
const ToggleLink = ({ items = [], rootClassName = "", onChange = (_value) => {}, defaultActiveItem = "" }) => {
  const isFFGCOM3695 = useFeatureFlag(GCOM_3695_ctaButtonColorUpdate);
  const classes = useStyles();
  const { t } = useTranslation();
  const [activeItem, setActiveItem] = useState(defaultActiveItem);
  const { isLoading } = useContext(DashBoardAppContext);
  const { freeTrialRole } = useUserState();
  const isTrialUser = !!freeTrialRole && Object.values(FreeTrialRole).includes(freeTrialRole);

  useEffect(() => {
    setActiveItem(defaultActiveItem);
  }, [defaultActiveItem]);

  const handleChange = (_event, newValue) => {
    setActiveItem(newValue);
    onChange(newValue);
  };

  return (
    <TooltipV2
      deactivate={!isTrialUser}
      title={t("Archive and deleting is not available in the Gartner BuySmart free trial.")}
    >
      <div className={rootClassName}>
        {items.length > 0 && (
          <Tabs
            classes={{ indicator: classes.tabsIndicator, root: classes.tabsRoot, scroller: classes.tabScroller }}
            onChange={handleChange}
            TabIndicatorProps={{ children: <span /> }}
            value={activeItem}
          >
            {items.map((item) => (
              <Tab
                key={item.value}
                classes={{
                  root: classNames(classes.tabRoot, isFFGCOM3695 && classes.tabRootGCOM3695),
                  selected: classes.tabSelected,
                  wrapper: classes.tabWrapper,
                }}
                disabled={isLoading || isTrialUser}
                disableRipple={true}
                label={item.label}
                value={item.value}
              />
            ))}
          </Tabs>
        )}
      </div>
    </TooltipV2>
  );
};

ToggleLink.propTypes = propTypes;
export default ToggleLink;
