import { useFeatureFlag } from "gx-npm-ui";
import { Fragment, useEffect } from "react";
import { useLocation } from "react-router-dom";
import singleSpa from "single-spa";
import { GCOM_3718_GCOM_3740__removeProfileModalFromApp } from "./lib/feature-flags";

const HandleRedirectsComponent = () => {
  const location = useLocation();
  const isRemovingProfileModalFFOn = useFeatureFlag(GCOM_3718_GCOM_3740__removeProfileModalFromApp);

  useEffect(() => {
    if (location.pathname.includes("/s/dashboard")) {
      if (isRemovingProfileModalFFOn) {
        singleSpa.navigateToUrl("/s/evaluations");
      } else {
        singleSpa.navigateToUrl("/s/evaluations" + location.search + location.hash);
      }
    }
  }, [isRemovingProfileModalFFOn, location]);

  return <Fragment />;
};

export default HandleRedirectsComponent;
