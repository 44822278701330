import { getAsyncRequest, useCaptureEventsV2, useUserState } from "gx-npm-lib";
import { createStateSubscription, destroyStateSubscription } from "gx-npm-messenger-util";
import {
  FreeTrialBannerComponent,
  GartnerFooter,
  Loader,
  SnackbarBanner,
  TypographyComponent,
  useFeatureFlag,
} from "gx-npm-ui";
import { Fragment, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import singleSpa from "single-spa";
import { GCOM_3718_GCOM_3740__removeProfileModalFromApp } from "./lib/feature-flags";
import profileRedirectCallback from "./lib/profile-redirect.lib";
import DashboardHeader from "./components/dashboard-header/dashboard-header.component";
import EvalCards from "./components/eval-cards/eval-cards.component";
import EvalNotStarted from "./components/eval-not-started/eval-not-started.component";
import { DashBoardAppContext } from "./app.context";
import { ClientEvent } from "./app.constants";
import styles from "./app-v3.styles.module.scss";

const AppV3Component = () => {
  const { t } = useTranslation();
  const { freeTrialDaysRemaining, freeTrialRole, firstName, hasInitiatives, hasTasks, isEntitled } = useUserState();
  const {
    currentState,
    isLoading,
    loadDataErrorStatus,
    saveDataErrorStatus,
    showSnackBar,
    snackbarMessage,
    userInitiatives,
    setIsLoading,
    setLoadDataErrorStatus,
    setSaveDataErrorStatus,
    setShowSnackBar,
    setUserInitiatives,
    setUserInitiativesOwnerCount,
  } = useContext(DashBoardAppContext);
  const isRemovingProfileModalFFOn = useFeatureFlag(GCOM_3718_GCOM_3740__removeProfileModalFromApp);
  const captureEvents = useCaptureEventsV2();

  useEffect(() => {
    captureEvents([{ eventType: ClientEvent.USER_LOADED_INITIATIVES_DASHBOARD }]);
  }, [captureEvents]);

  useEffect(() => {
    if (isRemovingProfileModalFFOn) {
      return;
    }
    const subscription = createStateSubscription("PROFILE_COMPLETE_STATUS", profileRedirectCallback);
    return () => {
      destroyStateSubscription(subscription);
    };
  }, [isRemovingProfileModalFFOn]);

  useEffect(() => {
    if (hasTasks && !hasInitiatives && !isEntitled) {
      singleSpa.navigateToUrl("/s/tasks-dashboard");
    }
  }, [hasTasks, hasInitiatives, isEntitled]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      let url = "";
      try {
        url = `/api/v2/user/dashboard/${currentState.toLowerCase()}`;
        const response = await getAsyncRequest(url);
        if (response?.status !== 200 || !response.data?.data) {
          throw new Error();
        }
        setUserInitiatives(response.data.data.initiatives || []);
        setUserInitiativesOwnerCount(response.data.data.initiativesOwnerCount || 0);
      } catch (err) {
        setLoadDataErrorStatus(true);
      }
      setIsLoading(false);
    })();
  }, [currentState, setIsLoading, setLoadDataErrorStatus, setUserInitiatives, setUserInitiativesOwnerCount]);

  return (
    <Fragment>
      <div className={styles.appContainer}>
        {freeTrialRole && (
          <FreeTrialBannerComponent daysRemaining={freeTrialDaysRemaining} freeTrialRole={freeTrialRole} />
        )}
        <div className={styles.titleContainer}>
          <TypographyComponent
            boldness="medium"
            color="gartnerBlue"
            element="h5"
            rootClassName={styles.titleHeader}
            styling="p2"
          >
            {t("Welcome back")}
            {firstName && `, ${firstName}`}
          </TypographyComponent>
        </div>
        <DashboardHeader />
        <div className={styles.contentContainer}>
          {isLoading && (
            <div className={styles.loaderContainer}>
              <Loader />
            </div>
          )}
          {!isLoading && userInitiatives.length > 0 && <EvalCards />}
          {!isLoading && userInitiatives.length === 0 && <EvalNotStarted />}
        </div>
        <SnackbarBanner isDefaultErrorMessage={true} isOpen={loadDataErrorStatus} setIsOpen={setLoadDataErrorStatus} />
        <SnackbarBanner isDefaultErrorMessage={true} isOpen={saveDataErrorStatus} setIsOpen={setSaveDataErrorStatus} />
        <SnackbarBanner isOpen={showSnackBar} message={snackbarMessage} setIsOpen={setShowSnackBar} type="SUCCESS" />
      </div>
      <GartnerFooter />
    </Fragment>
  );
};

export default AppV3Component;
