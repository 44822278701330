import { colorPalette, weightPalette } from "gx-npm-common-styles";

const styles = {
  tabsRoot: {
    borderBottom: "none",
  },
  tabsIndicator: {
    "&&": {
      background: "none",
      display: "flex",
      justifyContent: "center",
    },
    "& > span": {
      backgroundColor: colorPalette.neutrals.coal.hex,
      width: "32px",
    },
  },
  tabRoot: {
    "&&": {
      color: colorPalette.interactions.blueBerry.hex,
      minWidth: "auto",
      opacity: 1,
      padding: "0",
      textTransform: "none",
      "&$tabSelected, &:focus, &:hover": {
        color: colorPalette.neutrals.carbon.hex,
      },
      "& $tabWrapper": {
        borderRight: `1px solid ${colorPalette.neutrals.stone.hex}`,
        padding: "0 16px 4px",
        fontSize: "14px",
        fontVariationSettings: weightPalette.medium.variation,
        fontWeight: weightPalette.medium.amount,
        letterSpacing: "0.25px",
        lineHeight: "21px",
      },
      "&:last-child $tabWrapper": {
        borderRight: "none",
      },
      "&:disabled": {
        backgroundColor: "transparent !important",
        color: `${colorPalette.neutrals.iron.hex} !important`,
        opacity: 1,
      },
    },
  },
  tabRootGCOM3695: {
    "&&": {
      color: colorPalette.interactions.defaultCta.hex,
    },
  },
  tabScroller: {
    height: "40px",
  },
  tabWrapper: {},
  tabSelected: {},
};
export default styles;
