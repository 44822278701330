import { useTranslation } from "react-i18next";
import { FolderSmallIcon, StarV2Icon } from "gx-npm-icons";
import styles from "./eval-card-footer.module.scss";
import { colorPalette } from "gx-npm-common-styles";
import { LastActivity, Product } from "../../../app.types";
import { InitStatus, InitState, timeFromDateFormat } from "gx-npm-lib";
import { ProductLogo, TypographyComponent, UsernameDisplay } from "gx-npm-ui";
import { formatDate } from "../../../lib";
import { isEqual } from "date-fns";

type EvalCardFooterProps = {
  products: Product[];
  status: string;
  stateUpdateDate: string;
  initState: InitState;
  lastActivity: LastActivity;
};

const maxProductDisplay = 3;

const EvalCardFooterComponent = ({
  products,
  status,
  stateUpdateDate,
  initState,
  lastActivity,
}: EvalCardFooterProps) => {
  const { t } = useTranslation();

  const additionalProductCount = products.length - maxProductDisplay;
  const displayProductRow = products.slice(0, maxProductDisplay);

  const { firstName, lastName, accessDate } = lastActivity;

  const defaultDate = new Date("1970-01-01T00:00:00.000Z");
  const lastActivityDate = accessDate ? new Date(accessDate) : defaultDate;
  const showLastActivity = !isEqual(lastActivityDate, defaultDate);

  return (
    <div className={styles.evalCardFooter}>
      <div className={styles.products}>
        {products.length === 0 && (
          <TypographyComponent rootClassName={styles.noProducts} type="p4">
            {t("No vendors under consideration")}
          </TypographyComponent>
        )}
        {products.length > 0 && status === InitStatus.SELECTED && (
          <div className={styles.awardedProduct}>
            <ProductLogo
              logo={products[0].imageLoc}
              imageWidth={"32px"}
              imageHeight={"32px"}
              svgStyle={{ width: "28.8px", height: "28.5px" }}
            />
            <StarV2Icon rootClassName={styles.awardedIcon} fillPath={colorPalette.status.lemon.hex} />
            <TypographyComponent type="p3" boldness="semi">
              {t("Selected vendor")}
            </TypographyComponent>
          </div>
        )}
        {products.length > 0 && status !== InitStatus.SELECTED && (
          <div className={styles.productList}>
            {displayProductRow.map((product) => (
              <div className={styles.productLogo} key={product.productName}>
                <ProductLogo
                  logo={product.imageLoc}
                  imageWidth={"32px"}
                  imageHeight={"32px"}
                  svgStyle={{ width: "28.8px", height: "28.5px" }}
                />
              </div>
            ))}
            <TypographyComponent rootClassName={styles.moreProducts} type="p4">
              {additionalProductCount > 0 ? `+${additionalProductCount} more` : ""}
            </TypographyComponent>
          </div>
        )}
      </div>
      <div className={styles.lastStatus}>
        {initState === InitState.ACTIVE && showLastActivity && (
          <div className={styles.lastActivity}>
            <UsernameDisplay isSmallerIcon={true} name={`${firstName} ${lastName}`} showName={false} />
            <TypographyComponent rootClassName={styles.lastActivityTime} type="p4">
              Last activity {timeFromDateFormat(accessDate)}
            </TypographyComponent>
          </div>
        )}
        {initState === InitState.ARCHIVED && (
          <div className={styles.archivedOn}>
            <FolderSmallIcon height="16px" width="16px" />
            <TypographyComponent rootClassName={styles.archived} type="p4">
              {t("Archived on")} {formatDate(stateUpdateDate)}
            </TypographyComponent>
          </div>
        )}
        {initState === InitState.DELETED && (
          <div className={styles.deletedOn}>
            <TypographyComponent rootClassName={styles.deleted} type="p4">
              {t("Deleted")} {formatDate(stateUpdateDate)}
            </TypographyComponent>
          </div>
        )}
      </div>
    </div>
  );
};

export default EvalCardFooterComponent;
